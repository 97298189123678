import { defineStore } from 'pinia'
import { getLoginUserInfo, getPersonCustom, updatePersonCustom } from '@/axios'
import * as Thumbmarkjs from '@thumbmarkjs/thumbmarkjs'

export const useUserStore = defineStore({
  id: 'user',
  persist: true,
  state: () => {
    return {
      userInfo: {},
      newCode: '',
      lastSendCodeTime: null,
      fingerprintShow: false
    }
  },
  actions: {
    async initUserInfo() {
      await getLoginUserInfo()
        .then((res) => {
          if (res) {
            this.userInfo = res.data
          }
        })
        .catch(() => {})
    },
    async initBrowserInfo() {
      this.newCode = await Thumbmarkjs.getFingerprint()
      getPersonCustom({ key: 'fingerprint' }).then((res) => {
        let oldCode = []
        if (res.data) {
          oldCode = JSON.parse(res.data)
        }
        if (oldCode.length === 0) {
          this.updateBrowserInfo()
          this.fingerprintShow = false
        } else if (oldCode.includes(this.newCode)) {
          this.fingerprintShow = false
        } else {
          this.fingerprintShow = true
        }
      })
    },
    async updateBrowserInfo() {
      updatePersonCustom({ key: 'fingerprint', params: [this.newCode] }).then((res) => {})
    },
    setSendCodeTime(time) {
      this.lastSendCodeTime = time
    }
  }
})
