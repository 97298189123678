import {createRouter, createWebHistory} from 'vue-router'
import {ElMessage} from 'element-plus'
import {layout} from '@o2oa/component'
import {usePermissionStore} from '@/store/permission'
import {useDictStore} from '@/store/dict'
import {useCollectStore} from '@/store/collect'
import {useUserStore} from '@/store/user'
import {useStorageStore} from '@/store/storage'
import {useAppStore} from "@/store/app";
import { useWorkOrderImageConfigStore } from "@/store/workOrderImageConfig";
import HomeLayout from '@/layout/HomeLayout.vue'
import PageLayout from '@/layout/PageLayout.vue'
import FullLayout from '@/layout/FullLayout.vue'
import EmptyLayout from '@/layout/EmptyLayout.vue'
import Login from '@/views/Login/Login.vue'
import {getLoginUserInfo} from '@/axios'
import { useMessageStore } from "@/store/message";
import { useComponentStore } from "@/store/component";
import { useLoginStore } from "@/store/login";
import { useSearchParamsStore } from "@/store/searchParams";
import Cookies from "js-cookie";
// import { loadUtilsScript } from "@/utils/loadScript";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: '/Home',
      name: 'FullLayout',
      component: FullLayout,
      children: [
        {
          path: 'Login',
          name: 'Login',
          component: Login
        }
      ],
      meta: {
        noInit: true,
        isLogin: true
      }
    },
    {
      path: '/',
      component: EmptyLayout,
      children: [
        {
          path: 'CommonDetail',
          name: 'CommonDetail',
          component: () => import('@/views/CommonDetail/CommonDetail.vue'),
          meta: {
            name: '详情'
          }
        }
      ],
      meta: {
        noInit: true,
        isEmpty: true
      }
    },
    {
      path: '/',
      component: EmptyLayout,
      children: [
        {
          path: 'CommonApplyJob',
          name: 'CommonApplyJob',
          component: () => import('@/views/CommonPage/CommonApplyJob.vue'),
          meta: {
            name: '入职申请'
          }
        },
        {
          path: 'CommonApplyJobTransfer',
          name: 'CommonApplyJobTransfer',
          component: () => import('@/views/CommonPage/CommonApplyJobTransfer.vue'),
          meta: {
            name: '入职申请'
          }
        },
        {
          path: 'MonitoringLargeScreen',
          name: 'MonitoringLargeScreen',
          component: () => import('@/views/MonitoringLargeScreen/MonitoringLargeScreen.vue'),
          meta: {
            name: '监控大屏'
          }
        },
        {
          path: 'PurchaseLargeScreen',
          name: 'PurchaseLargeScreen',
          component: () => import('@/views/PurchaseLargeScreen/PurchaseLargeScreen.vue'),
          meta: {
            name: '监控大屏'
          }
        },
        {
          path: 'PersonalLargeScreen',
          name: 'PersonalLargeScreen',
          component: () => import('@/views/PersonalLargeScreen/PersonalLargeScreen.vue'),
          meta: {
            name: '监控大屏'
          }
        },
      ],
      meta: {
        noInit: true,
        noUser: true
      }
    },
    {
      path: '/Home',
      name: 'HomeLayout',
      redirect: '/HomePageSystemHome',
      component: HomeLayout,
      children: []
    },
    {
      path: '/',
      name: 'PageLayout',
      component: PageLayout,
      children: []
    }
  ]
})
let permissionStore
let dictStore
let collectStore
let userStore
let storageStore
let appStore
let workOrderImageConfigStore
let messageStore
let componentStore
let loginStore
let searchParamsStore
let hasInit = false

router.beforeEach(async (to, form, next) => {
  if(!componentStore){
    componentStore = useComponentStore()
  }
  componentStore.closeProcessDrawer()
  if(!loginStore){
    loginStore = useLoginStore()
  }
  loginStore.setTheme()
  let { matched } = to
  if (matched && matched[0]?.meta.noInit) {
    if(matched[0]?.meta.isEmpty){
      dictStore = useDictStore()
      userStore = useUserStore()
      await Promise.all([
        userStore.initUserInfo(),
        dictStore.initDictList()
      ])
    }
    if(matched[0]?.meta.noUser){
      console.log('noUser')
    }
    if(matched[0]?.meta.isLogin){
      loginStore.initLoginConfig()
    }
    hasInit = false
    next()
  } else {
    let hasLogin = await getLoginUserInfo()
      .then(async (res) => {
        let data = res.data
        if (!data.token) {
          Cookies.remove('x-token')
          return false
        }
        try {
          layout.user = data
          layout.session = layout.session || {}
          layout.session.user = data
          layout.session.token = data.token
          layout.desktop.session = layout.session
          if (!hasInit) {
            permissionStore = usePermissionStore()
            dictStore = useDictStore()
            collectStore = useCollectStore()
            userStore = useUserStore()
            storageStore = useStorageStore()
            appStore = useAppStore()
            workOrderImageConfigStore = useWorkOrderImageConfigStore()
            messageStore = useMessageStore()
            searchParamsStore = useSearchParamsStore()
            await permissionStore.initMenuTree()
            await permissionStore.initPermissions()
            await permissionStore.initRouter(router,next,to)
            await Promise.all([
              userStore.initUserInfo(),
              dictStore.initDictList(),
              searchParamsStore.initSearchParamsList(),
              appStore.initAppList(),
              workOrderImageConfigStore.initWorkOrderImageConfigList(),
              messageStore.getTaskPageCount(),
              collectStore.initCollectList()
            ])
            if(import.meta.env.VITE_MODE !== 'development'){
              await userStore.initBrowserInfo()
            }
            // console.log(123)
            // await loadUtilsScript('jquery/jquery-1.8.3.min.js')
            // await loadUtilsScript('vue-plugin-hiprint.js')
            // import '@/utils/jquery/jquery-1.8.3.min'
            // import '@/utils/vue-plugin-hiprint'
            hasInit = true
            console.log('初始化逻辑完成')
          }
        } catch (e) {
          console.log('初始化逻辑错误')
          console.log(e)
          return false
        }
        return true
      })
      .catch(async (err) => {
        console.log('获取登录用户错误')
        console.log(err)
        return false
      })
    if (hasLogin) {
      let routerName = to.path.replace('/', '')
      if (router.hasRoute(routerName)) {
        permissionStore.setModuleByRouter(routerName)
        storageStore.addRouterHistory(to.path)
        next()
      } else {
        storageStore.deleteRouterHistory(to.path)
        next({
          path: '/Home'
        })
      }
    } else {
      ElMessage.error('您未登录，请登录')
      next({
        path: '/Login'
      })
    }
  }
})
// router.afterEach((to, from)=>{
//   console.log(to);
//   console.log(from);
// })
export default router;
