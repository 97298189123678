export default {
  name: "auto-open",
  // 当被绑定的元素挂载完成时
  mounted(el, binding) {
    // console.log('mounted')
  },
  updated(el, binding) {
    // console.log('updated')
    // console.log(el)
    // console.log(binding)
  },
  activated(el, binding) {
    console.log('activated')
  },
  deactivated(el, binding) {
    console.log('deactivated')
  },
  unmounted(el, binding) {
    // console.log('unmounted')
  }
};