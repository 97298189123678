import { defineStore } from 'pinia'
import { getProcessDetail, processSubmit, processWork } from '@/axios'
import { layout } from '@o2oa/component'

export const useComponentStore = defineStore({
  id: 'component',
  state: () => {
    return {
      processDrawer: {
        workId: null,
        taskId: null,
        priorityWork: null,
        processPcApp: null,
        show: false,
        callback: null,
        detailName: '',
        editName: '',
        editId: null,
        showDetailDrawer: false,
        showEditDrawer: false,
        props:{}
      }
    }
  },
  getters: {},
  actions: {
    showProcessDetail(row, callback) {
      console.log(row);
      let { creatorPerson, activityName, processPcApp } = row
      let userInfo = layout.user
      if (creatorPerson === userInfo.distinguishedName && activityName === '拟稿' && processPcApp) {
        getProcessDetail({ id: row.work || row.id || row.workId }).then((res) => {
          if (res) {
            // 调转自定义详情
            this.showCustomDetailDrawer(res.data.data.id, processPcApp, callback)
          }
        })
      } else if (processPcApp && processPcApp.includes('_Edit')) {
        processPcApp = processPcApp.replace('_Edit', '')
        getProcessDetail({ id: row.work || row.id || row.workId }).then((res) => {
          if (res) {
            this.showCustomDetailDrawer(res.data.data.id, processPcApp, callback)
          }
        })
      } else if (row.completed) {
        let workParams = {
          workId: row.workCompleted,
          priorityWork: row.work
        }
        this.showProcessDrawer(workParams, callback)
      } else if (row.work) {
        let workParams = {
          workId: row.work,
          taskId: row.id
        }
        this.showProcessDrawer(workParams)
      } else if (row.job) {
        let workParams = {
          workId: row.id,
          taskId: row.job
        }
        this.showProcessDrawer(workParams)
      } else if (row.workId) {
        let workParams = {
          workId: row.workId,
          taskId: row.jobId
        }
        this.showProcessDrawer(workParams)
      }
    },
    showCustomDetailDrawer(id, processApp, callback = null,props) {
      this.setCustomComponent(id, processApp, callback,props)
      setTimeout(() => {
        this.processDrawer.showDetailDrawer = true
      }, 100)
    },
    showCustomEditDrawer(id, processApp, callback = null,props) {
      this.setCustomComponent(id, processApp, callback,props)
      setTimeout(() => {
        this.processDrawer.showEditDrawer = true
      }, 100)
    },
    setCustomComponent(id, processApp, callback = null,props = {}) {
      this.processDrawer.editId = id
      this.processDrawer.detailName = `${processApp}Detail`
      this.processDrawer.editName = `${processApp}Edit`
      this.processDrawer.callback = callback
      this.processDrawer.props = props
    },
    showEditDrawer() {
      this.processDrawer.showDetailDrawer = false
      setTimeout(() => {
        this.processDrawer.showEditDrawer = true
      })
    },
    showProcessDrawer(workParams, callback = null) {
      this.processDrawer.show = false
      this.processDrawer.workId = workParams.workId || ''
      this.processDrawer.taskId = workParams.taskId || ''
      this.processDrawer.priorityWork = workParams.priorityWork || ''
      this.processDrawer.processPcApp = workParams.processPcApp || null
      this.processDrawer.callback = callback
      this.processDrawer.show = true
    },
    closeProcessDrawer() {
      this.processDrawer.show = false
    }
  }
})
