<script>
import { defineComponent } from 'vue'

export default defineComponent({
  mixins: [],
  data() {
    return {}
  },
  async mounted() {
    window.addEventListener('beforeunload', function(e) {
      const message = '您确定要离开此页面吗？未保存的更改将会丢失。'
      e.preventDefault();
      e.returnValue = message
      return message
    })
  },
  beforeUnmount() {},
  methods: {}
})
</script>

<template>
  <RouterView />
</template>

<style scoped lang="scss"></style>